import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useScoringQuery } from 'services/api/useQuotasBackend';
import { StatusTable } from './StatusTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionDetails: {
      padding: 0,
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
    valid: {
      color: theme.palette.success.main,
    },
    validWithConfirm: {
      color: theme.palette.warning.main,
    },
    noValid: {
      color: theme.palette.error.main,
    },
    locked: {
      filter: 'grayscale(1) brightness(1.2)',
    },
  }),
);

export type ScoringListProps = {
  quotaId: number;
};

export const ScoringList = (props: ScoringListProps) => {
  const classes = useStyles();
  const { quotaId } = props;
  const { data } = useScoringQuery(quotaId);
  const statusRows = data?.approvals ?? [];
  const isLocked = data?.isLocked ?? false;
  const selectedScoringModelId = data?.selectedScoringModelId;

  return (
    <>
      <Accordion expanded={true} className={isLocked ? classes.locked : ''}>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <div className={classes.title}>
            <Typography variant="subtitle1">Модели решения</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <StatusTable
            rows={statusRows}
            isLocked={isLocked}
            quotaId={quotaId}
            selectedScoringModelId={selectedScoringModelId}
            availableAction={data?.availableAction}
            scoringIssue={data?.scoringIssue}
            contractIssue={data?.contractIssue}
            scoringApprovalIssue={data?.scoringApprovalIssue}
            isHaveUnapprovedNomenclature={data?.isHaveUnapprovedNomenclature}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
