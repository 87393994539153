import { makeStyles, createStyles, Theme, CardHeader } from '@material-ui/core';
import { IssueStatus, IssueViewModel, ScoringApprovalViewModel, ScoringRouteAction } from 'schema';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { ScoringModelTable } from './ScoringModelTable';
import { Sidebar } from '../../Sidebar';
import { Action } from './Action';
import { Task } from './Task';
import { useTranslation } from 'react-i18next';
import useFileDownloader from '../../utils/useFileDownloader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'grid',
      gridTemplateColumns: '150px 70px 16px 1fr auto 200px',
      gridGap: theme.spacing(0, 2),
      fontSize: 14,
      fontWeight: 600,
      padding: theme.spacing(0),
      color: theme.palette.text.primary,
      alignItems: 'center',
      '&$selected': {
        background: 'linear-gradient(90deg, #EEF9E8 0%, #FFFFFF 100%)',
      },
      '&$notSelected': {
        filter: 'grayscale(1) brightness(1.4)',
      },
    },

    selected: {},
    notSelected: {},
    name: {
      padding: theme.spacing(2, 0, 2, 2),
      lineHeight: '40px',
      color: theme.palette.text.primary,
      borderLeft: `4px solid ${theme.palette.text.secondary}`,
      '&$rejected': {
        //backgroundColor: '#FFEEE6',
        borderLeft: `4px solid ${theme.palette.primary.main}`,
      },
      '&$approved': {
        //backgroundColor: theme.palette.success.light,
        borderLeft: `4px solid ${theme.palette.success.main}`,
      },
    },
    first: {},
    rejected: {},
    approved: {},
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '& $dropdown': {
        transform: 'rotate(180deg)',
      },
    },
    dropdown: {},
    decision: {
      display: 'flex',
      alignItems: 'center',
      '& $rejected': {
        color: theme.palette.primary.main,
      },
      '& $approved': {
        color: theme.palette.success.main,
      },
    },
    viewIcon: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    tasks: {},
    action: {
      paddingRight: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    notValidRules: {
      gridColumn: 'span 6',
      padding: theme.spacing(0, 2.5),
    },
    notValidRulesLink: {},
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    contract: {
      display: 'flex',
    },
    contractTitle: {
      padding: theme.spacing(0, 0.5, 0, 0),
    },
    contractNumber: {
      color: theme.palette.secondary.dark,
      padding: theme.spacing(0, 0.5),
      borderRight: `1px solid ${theme.palette.text.secondary}`,
      whiteSpace: 'nowrap',
    },
    contractStatus: {
      padding: theme.spacing(0, 0.5),
    },
  }),
);

type ScoringModelApprovalStatusProps = {
  row: ScoringApprovalViewModel;
};

const ApprovalStatus = (props: ScoringModelApprovalStatusProps) => {
  const classes = useStyles();
  const {
    row: {
      result: { isValid },
      isAutomatic,
      issue,
    },
  } = props;

  const status =
    isAutomatic || (issue !== undefined && issue.status === IssueStatus.Closed)
      ? isValid
        ? 'Одобрено'
        : 'Отклонено'
      : '—';

  const statusClass =
    isAutomatic || (issue !== undefined && issue.status === IssueStatus.Closed)
      ? isValid
        ? classes.approved
        : classes.rejected
      : '';

  return <div className={statusClass}>&nbsp;{status}</div>;
};

export type StatusItemProps = {
  row: ScoringApprovalViewModel;
  index: number;
  quotaId: number;
  isLocked: boolean;
  selectedScoringModelId?: number;
  availableAction?: ScoringRouteAction;
  scoringIssue?: IssueViewModel;
  scoringApprovalIssue?: IssueViewModel;
  contractIssue?: IssueViewModel;
  issue?: IssueViewModel;
  isHaveUnapprovedNomenclature?: boolean;
};

export const StatusItem = (props: StatusItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    row,
    quotaId,
    selectedScoringModelId,
    availableAction,
    scoringIssue,
    scoringApprovalIssue,
    contractIssue,
    isHaveUnapprovedNomenclature,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [sideOpen, setSideOpen] = useState<boolean>(false);
  const isSelected = selectedScoringModelId === row.id;

  const approvalFile = scoringApprovalIssue?.approval?.file;
  const downloadUrl = approvalFile?.downloadUrl ?? '';
  const fileName = approvalFile?.fileName ?? '';

  const downloadFile = useFileDownloader();
  const getFile = useCallback(
    async (ev) => {
      await downloadFile(ev, downloadUrl, fileName);
    },
    [downloadFile, fileName, downloadUrl],
  );

  const showRules = useCallback(() => {
    setSideOpen(true);
  }, [setSideOpen]);

  const toggleNotValidRules = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  const allRules = row.result.rules ?? [];
  const notValidRules =
    allRules.filter((rule) =>
      row.name !== 'ФКМ Розница' ? !rule.isValid : !rule.isValid && !rule.code.startsWith('A'),
    ) ?? [];

  const statusClass =
    row.isAutomatic || (row.issue !== undefined && row.issue.status === IssueStatus.Closed)
      ? row.result.isValid
        ? classes.approved
        : classes.rejected
      : '';

  return (
    <div
      className={clsx(
        classes.row,
        selectedScoringModelId === undefined
          ? ''
          : isSelected
            ? classes.selected
            : classes.notSelected,
      )}
    >
      <div className={clsx(classes.name, statusClass)}>{row.name}</div>
      <div className={classes.decision}>
        <ApprovalStatus row={row} />
      </div>
      <div
        onClick={row.isAutomatic ? showRules : approvalFile ? getFile : undefined}
        className={classes.viewIcon}
      >
        {allRules.length > 0 && (
          <IconSprite icon={'view'} width={16} color={theme.palette.text.secondary} />
        )}
        {approvalFile && (
          <IconSprite icon={'download3'} width={16} color={theme.palette.text.secondary} />
        )}
      </div>
      {contractIssue && isSelected ? (
        <div className={classes.contract}>
          <div className={classes.contractTitle}>{t('Contract')}:</div>
          <div className={classes.contractNumber}>{contractIssue.contractNumber}</div>
          <div className={classes.contractStatus}>{contractIssue.contractStatus ?? '—'}</div>
        </div>
      ) : (
        <div className={classes.notValidRulesLink} onClick={toggleNotValidRules}>
          {notValidRules.length > 0 && (
            <>
              Отказ по{' '}
              <span className={classes.link}>
                {notValidRules.length} правилам &nbsp;
                <IconSprite
                  className={open ? classes.dropdown : ''}
                  icon="dropdown2"
                  width={10}
                  height={6}
                  color={theme.palette.primary.main}
                />
              </span>
            </>
          )}
        </div>
      )}

      <div className={classes.tasks}>
        {isSelected && (
          <>
            {scoringIssue && <Task issue={scoringIssue} />}
            {scoringApprovalIssue && <Task issue={scoringApprovalIssue} />}
            {contractIssue && <Task issue={contractIssue} />}
          </>
        )}
      </div>
      <div className={classes.action}>
        {
          <Action
            isValid={row.result.isValid}
            isAutomatic={row.isAutomatic}
            quotaId={quotaId}
            modelId={row.id}
            isSelected={isSelected}
            availableAction={availableAction}
            isHaveUnapprovedNomenclature={isHaveUnapprovedNomenclature}
          />
        }
      </div>
      {open && (
        <div className={classes.notValidRules}>
          <ScoringModelTable rows={notValidRules} type="notValid" />
        </div>
      )}
      {sideOpen && (
        <Sidebar width={838} onClose={() => setSideOpen(false)}>
          <CardHeader className={classes.header} title={`Модель решения ${row.name}`} />
          <ScoringModelTable rows={allRules} type="all" />
        </Sidebar>
      )}
    </div>
  );
};
