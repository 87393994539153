import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ScoringRouteAction } from 'schema';
import { themeOrange as theme } from 'theme';
import React, { useCallback } from 'react';
import { Button } from '../../Button';
import { useTranslation } from 'react-i18next';
import { useCreateScoringIssueMutation } from '../../../services';
import { Link, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useToast } from '../../Toast';
import { useHistory } from 'react-router';
import { ModalForm, useModalForm } from '../../Modal';
import { ApprovalRequestForm } from '../CalculationForm/ApprovalRequestForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  }),
);

type ActionProps = {
  isValid: boolean;
  quotaId: number;
  modelId: number;
  isAutomatic: boolean;
  isSelected: boolean;
  availableAction?: ScoringRouteAction;
  isHaveUnapprovedNomenclature?: boolean;
};

export const Action = (props: ActionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    quotaId,
    isValid,
    modelId,
    availableAction,
    isSelected,
    isAutomatic,
    isHaveUnapprovedNomenclature,
  } = props;

  const { mutateAsync } = useCreateScoringIssueMutation(quotaId ?? 0, {
    onSuccess: (result) => {
      toast(
        <>
          Создана задача №
          <Link
            to={`/tasks/${result.id}`}
            style={{ color: theme.palette.primary.main }}
            className={classes.link}
          >
            {result.id}
          </Link>
        </>,
        'success',
      );
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey) && query.queryKey[0] === 'quotas') {
            return true;
          }

          if (typeof query.queryKey === 'string') {
            const key = query.queryKey as string;
            return ['quotas', 'scoring', 'margin'].some((substring) => key.includes(substring));
          }

          return false;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const history = useHistory();
  const location = useLocation();
  const contractHandler = () => {
    history.push(location.pathname, { tab: t('Contract') });
  };

  const onClickHandler = useCallback(() => mutateAsync({ id: modelId }), [modelId, mutateAsync]);

  const {
    onOpen: onOpenApprovalRequest,
    onClose: onCloseApprovalRequest,
    open: openApprovalRequest,
  } = useModalForm();

  return (
    <>
      {availableAction === ScoringRouteAction.Select ? (
        <Button
          color="primary"
          size="medium"
          type="button"
          variant="contained"
          disabled={isAutomatic && !isValid}
          onClick={onClickHandler}
        >
          {t('Transfer to IS')}
        </Button>
      ) : availableAction === ScoringRouteAction.Approve && isSelected ? (
        <Button
          color="primary"
          size="medium"
          type="button"
          variant="contained"
          disabled={isAutomatic || isHaveUnapprovedNomenclature}
          onClick={onOpenApprovalRequest}
        >
          {t('On approve')}
        </Button>
      ) : availableAction === ScoringRouteAction.SendToExecution && isSelected ? (
        <Button
          color="primary"
          size="medium"
          type="button"
          variant="contained"
          disabled={isAutomatic && !isValid}
          onClick={contractHandler}
        >
          {t('Draw up contract')}
        </Button>
      ) : null}
      <ModalForm open={openApprovalRequest} onClose={onCloseApprovalRequest}>
        <ApprovalRequestForm quotaId={quotaId} onClose={onCloseApprovalRequest} />
      </ModalForm>
    </>
  );
};
