import { useTranslation } from 'react-i18next';
import { TelematicsValue, useCalculationFormContext } from '../types';
import { Select } from 'components';
import { MenuItem } from '@material-ui/core';
import { Telematics } from 'schema/serverTypes';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useTelematicsData } from '../../../dictionaries/Telematics/useTelematicsData';

export const TelematicsSelect = () => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useCalculationFormContext();

  const telematics = useWatch({ control, name: 'telematics' });

  useEffect(() => {
    const krimistopTelematicsCount = getValues('krimistopTelematicsCount');
    const telematicsOneTimeAmount = getValues('telematicsOneTimeAmount');
    const telematicsMonthlyAmount = getValues('telematicsMonthlyAmount');

    if (telematics !== Telematics.XproKrimistop && telematics !== Telematics.Krimistop) {
      if (krimistopTelematicsCount !== undefined) {
        setValue('krimistopTelematicsCount', undefined);
      }
    }

    if (telematics === Telematics.Caesar) {
      if (telematicsOneTimeAmount !== TelematicsValue.caesar.oneTime) {
        setValue('telematicsOneTimeAmount', TelematicsValue.caesar.oneTime);
      }
      if (telematicsMonthlyAmount !== TelematicsValue.caesar.monthly) {
        setValue('telematicsMonthlyAmount', TelematicsValue.caesar.monthly);
      }
    } else if (telematics === Telematics.XPro || telematics === Telematics.XproKrimistop) {
      if (telematicsOneTimeAmount !== TelematicsValue.xPro.oneTime) {
        setValue('telematicsOneTimeAmount', TelematicsValue.xPro.oneTime);
      }
      if (telematicsOneTimeAmount !== TelematicsValue.xPro.oneTime) {
        setValue('telematicsMonthlyAmount', TelematicsValue.xPro.monthly);
      }
    } else if (telematics === Telematics.Krimistop) {
      if (telematicsOneTimeAmount !== 0) {
        setValue('telematicsOneTimeAmount', 0);
      }
      if (telematicsMonthlyAmount !== TelematicsValue.xPro.oneTime) {
        setValue('telematicsMonthlyAmount', 0);
      }
    }
  }, [telematics, setValue, getValues]);

  const { rows } = useTelematicsData();
  const options = rows.filter((row) => row.enabled);

  return (
    <Select name="telematics" label={t('Telematics')} control={control}>
      {options.map((option) => (
        <MenuItem key={option.telematics} value={option.telematics}>
          {option.name}
        </MenuItem>
      ))}
      <MenuItem value={Telematics.None}>{t('No')}</MenuItem>
    </Select>
  );
};
